import AEGIS from "../assets/svg/AEGIS - SISTEM AKADEMIK.svg";
import INDIPRO from "../assets/svg/INDIPRO TELKOM SBY.svg";
import ZELLTECH from "../assets/svg/E-SALES ZELLTECH.svg";
import MJSUNIlEVER from "../assets/svg/SISTEM WAREHOUSE - MJA UNILEVER.svg";
import WIKA from "../assets/svg/QPS WIKA GEDUNG.svg";
import RESCUE from "../assets/svg/RESCUE.ID.svg";
import SILY from "../assets/svg/SILY.svg";
import ETRUCKING from "../assets/images/E-TRUCKING.jpg";
import ARCHIREON from "../assets/images/ARCHIRECON.jpg";
import BMOMS from "../assets/svg/BMOMS.svg";
import PORTALDONASIYAYASAN from "../assets/svg/PORTAL DONASI YAYASAN.svg";
import SIMASTERBKD from "../assets/images/simasterbkd.jpg";
import SIPINTAR from "../assets/svg/SIPINTAR.svg";
// import BANKBENGKULU from "../assets/svg/RESERVASI KREDIT BANK BENGKULU.svg";
import TOWERINYOURHAND from "../assets/svg/TOWER IN YOUR HAND.svg";
import DIANCAGOODS from "../assets/images/DIANCA-GOODS.jpg";
import MANUFACTURINGUBAYA from "../assets/svg/PROMOTION VIDEO TEKNIK MANUFAKTUR UBAYA.svg";
import SPA from "../assets/svg/REBRANDING DIGITAL MARKETING QUEEN AISYA SALON _ SPA.svg";
import PPDBBENGKULU from '../assets/svg/PPDB-SMPN-BENGKULIU.webp';
import TATARUANGKABBANJARBARU from '../assets/svg/TATA-RUANG-KAB-BANJARBARU.webp';
import PJB from '../assets/svg/pjb.svg';
import DIGITALARCHIVE from '../assets/images/digital archive lamongan.jpg';
import JOBCENTER from '../assets/images/Job-center.jpg';
import BMTMUDA from '../assets/images/bmt-muda.jpg';
import TAMBANG from '../assets/images/tambang.jpg';
import KPI from '../assets/images/kepiins.jpg';
import KANA from '../assets/images/kana.jpg';
import PPDBITS from '../assets/images/ppdbitsconsultant.jpg';
import KONTRAKTOR from '../assets/images/konsultankontraktor.jpg';
import MANUFAKTURINDUSTRI from '../assets/images/manufakturindustri.jpg';
import ANALISABMTMUDA from '../assets/images/analisabmtmuda.jpg'
import POCJOBCENTER from '../assets/images/pocjobcenter.jpg';
import POCOPTA from '../assets/images/pocopta.jpg';
import POCRCS from '../assets/images/pocrcs.jpg';
import CALCIUSINKUBATOR from '../assets/images/inkubatorcalcius.jpg';
import MANAJEMENLOGISTIK from '../assets/images/manajemenlogistik.jpg';
import RETAILECOMMERCE from '../assets/images/retailecommerce.jpg';
import MANAJEMENGUDANG from '../assets/images/sistemmanajemengudang.jpg';
import MANAJEMENSTOK from '../assets/images/sistemmanajemenstok.jpg';
import APLIKASIAKUNTANSI from '../assets/images/sistemaplikasiakuntansi.jpg';
import POINTOFSALES from '../assets/images/pointofsales.jpg';


export const dataWork = [
  {
    id: 1,
    tag: 'Software Development',
    title: 'INDIPRO TELKOM SURABAYA',
    desc: 'Effortless online booking experience for home internet installation, enriched with advanced GIS features',
    images: INDIPRO,
    uploadStatus: 'new',
  },
  {
    id: 2,
    tag: 'Software Development',
    title: 'AEGIS - SISTEM AKADEMIK',
    desc: 'Empowering educators and students of the Faculty of Dentistry, Universitas Airlangga with our academic system application',
    images: AEGIS,
    uploadStatus: 'new',
  },
  {
    id: 3,
    tag: 'Software Development',
    title: 'E-SALES ZELLTECH',
    desc: "Zelltech's application: A powerful tool for PT Celcius Indo Perkasa, enabling seamless sales tracking and project monitoring",
    images: ZELLTECH,
    uploadStatus: 'new',
  },
  {
    id: 4,
    tag: 'Software Development',
    title: 'SISTEM WAREHOUSE - MJS UNILEVER',
    desc: 'Efficient system facilitating inventory tracking for MJA Company, a trusted Unilever distributor',
    images: MJSUNIlEVER,
    uploadStatus: 'regular',
  },
  {
    id: 5,
    tag: 'Software Development',
    title: 'QPS WIKA GEDUNG',
    desc: 'Revolutionizing property assessments, PT. WIKA Gedung employs an innovative application for surveying residential units at Puncak CBD Apartments',
    images: WIKA,
    uploadStatus: 'regular',
  },
  {
    id: 6,
    tag: 'Software Development',
    title: 'RESCUE.ID',
    desc: 'Empowering emergency response at Dr. Soetomo Hospital, Surabaya. Our application offers reporting and guidance for heart attack emergencies, ensuring swift Code Blue interventions',
    images: RESCUE,
    uploadStatus: 'regular',
  },
  {
    id: 7,
    tag: 'Software Development',
    title: 'SILY',
    desc: "Unveiling the Lamongan District's Smart City Portal App. Explore 360-degree photos, report issues, and ensure swift emergency responses with advanced features",
    images: SILY,
    uploadStatus: 'regular',
  },
  {
    id: 8,
    tag: 'Software Development',
    title: 'TOWER IN YOUR HAND',
    desc: 'Application for the supervision of telecommunication towers in Pemeksaan Regency by the Department of Communication and Information Technology',
    images: TOWERINYOURHAND,
    uploadStatus: 'regular',
  },
  {
    id: 9,
    tag: 'Software Development',
    title: 'SIPINTAR',
    desc: 'Application used by the Public Works and Housing Department of Banjarbaru City to search for information on spatial planning.',
    images: SIPINTAR,
    uploadStatus: 'regular',
  },
  {
    id: 10,
    tag: 'Software Development',
    title: 'BMOMS',
    desc: 'Revolutionize maternal care with our mental health screening app for expectant mothers. Centrally recorded results at Dinkes, automating referrals to KIA and Keswa at Puskesmas',
    images: BMOMS,
    uploadStatus: 'regular',
  },
  {
    id: 11,
    tag: 'Software Development',
    title: 'PORTAL DONASI YAYASAN',
    desc: 'A platform that facilitates donations for mosque construction projects under the auspices of the Rodioutl Falah Surabaya foundation',
    images: PORTALDONASIYAYASAN,
  },
  {
    id: 12,
    tag: 'Software Development',
    title: 'SIMASTER BKD',
    desc: 'Human resources information system for East Java province integrating multiple databases on a Hybrid device. The result of collaboration with the East Java Regional Personnel Agency',
    images: SIMASTERBKD,
    uploadStatus: 'regular',
  },
  {
    id: 13,
    tag: 'Software Development',
    title: 'JOB CENTER BERAU COAL ENERGY',
    desc: "Website and mobile based information systems used to register, track progress, and announce registration status, as well as a platform for taking part in job fair events for Berau Coal Energy.",
    images: JOBCENTER,
    uploadStatus: 'regular',
  },
  // {
  //   id: 14,
  //   tag: 'Software Development',
  //   title: 'RESERVASI KREDIT BANK BENGKULU',
  //   desc: 'Online reservation system for physical evidence to simplify and expedite credit applications for the Regional-Owned Enterprises (BUMD) of Bank Bengkulu',
  //   images: BANKBENGKULU,
  //   uploadStatus: 'regular',
  // },
  {
    id: 15,
    tag: 'Software Development',
    title: 'E-TRUCKING',
    desc: "Optimize monitoring for PT Javara Multiindo Fertilizo's mining trucks with our efficient check-in and check-out application",
    images: ETRUCKING,
    uploadStatus: 'regular',
  },
  {
    id: 16,
    tag: 'Software Development',
    title: 'DIANCA GOODS',
    desc: 'User-friendly cosmetic e-commerce site for both customers and dropshippers. The system is also integrated with WhatsApp for registration and verification processes',
    images: DIANCAGOODS,
    uploadStatus: 'regular',
  },
  {
    id: 17,
    tag: 'Software Development',
    title: 'PPDB SMPN BENGKULU',
    desc: 'Application and system used to assist and accommodate the process of admitting new students for school in Bengkulu province.',
    images: PPDBBENGKULU,
    uploadStatus: 'regular',
  },
  {
    id: 18,
    tag: 'Software Development',
    title: 'PJB UNITE',
    desc: 'Application that accommodates social media, group interaction, chat, and monitoring management system for PJB Services.',
    images: PJB,
    uploadStatus: 'regular',
  },
  {
    id: 19,
    tag: 'Software Development',
    title: 'SISTEM MANAJEMEN POLA RUANG KAB. BANJARBARU',
    desc: 'Application used to search for information on the type of area, display types of activities, and apply for development permits.',
    images: TATARUANGKABBANJARBARU,
    uploadStatus: 'regular',
  },
  {
    id: 20,
    tag: 'Software Development',
    title: 'DIGITAL ARCHIVE DISDUKCAPIL KAB. LAMONGAN',
    desc: 'Digital archive is a website-based application for digitizing documents based on certain categories such as year, name and national identity number. The document can be archived based on the label specified by the admin.',
    images: DIGITALARCHIVE,
    uploadStatus: 'regular',
  },
  {
    id: 21,
    tag: 'Software Development',
    title: 'ARCHIRECON',
    desc: "Mobile-based application system for company profiles and ordering Archirecon's architectural designs",
    images: ARCHIREON,
    uploadStatus: 'regular',
  },
  {
    id: 22,
    tag: 'Software Development',
    title: 'BMT MUDA',
    desc: "Website-based information system for BMT MUDA that provides information about organizational activities and accommodates the implementation of zakat, maal, waqf",
    images: BMTMUDA,
    uploadStatus: 'regular',
  },
  {
    id: 23,
    tag: 'Software Development',
    title: 'Sistem Aplikasi Pertambangan (Raw Material Supply Chain)',
    desc: "Sistem Aplikasi Pertambangan (Raw Material Supply Chain) is a website-based supply chain information system with the ability to manage raw materials from mines and analyze the stock of raw material types found in each mine.",
    images: TAMBANG,
    uploadStatus: 'regular',
  },
  {
    id: 24,
    tag: 'Software Development',
    title: 'Sistem Aplikasi Penilaian Kinerja Pegawai',
    desc: "Web-based application system used to analyze the performance value index of marketing employees at mining companies, and is able to display employee promotion recommendations for management based on the performance value of each employee.",
    images: KPI,
    uploadStatus: 'regular',
  },
  {
    id: 25,
    tag: 'Software Development',
    title: 'Sistem Aplikasi E-Commerce ATK',
    desc: "Online shop application developed based on Android and IOS App, which is used for customers to be able to shop for office stationery online at each shop outlet.",
    images: RETAILECOMMERCE,
    uploadStatus: 'regular',
  },
  {
    id: 26,
    tag: 'Software Development',
    title: 'Sistem Manajemen Logistik',
    desc: "Website-based logistics management application system, which helps users to manage transactions and logistics documents such as manifests, invoices, unloading lists, and other logistics needs.",
    images: MANAJEMENLOGISTIK,
    uploadStatus: 'regular',
  },
  {
    id: 27,
    tag: 'Software Development',
    title: 'Sistem Manajemen Gudang',
    desc: "Website and Android based application system used to manage Distribution Centers such as PO to suppliers, stock forecasting, branch deliveries and branch goods receipts.",
    images: MANAJEMENGUDANG,
    uploadStatus: 'regular',
  },
  {
    id: 28,
    tag: 'Software Development',
    title: 'Sistem Manajemen Stok',
    desc: "Java desktop-based, website-based, and Android-based application systems used for monitoring and managing stock at each outlet and distribution center.",
    images: MANAJEMENSTOK,
    uploadStatus: 'regular',
  },
  {
    id: 29,
    tag: 'Software Development',
    title: 'Sistem Aplikasi Akuntansi',
    desc: "Website-based accounting application system used to generate reports required by accounting staff.",
    images: APLIKASIAKUNTANSI,
    uploadStatus: 'regular',
  },
  {
    id: 30,
    tag: 'Software Development',
    title: 'Sistem Aplikasi Point of Sales',
    desc: "Java Desktop-based point of sales application system used to conduct sales transactions at each outlet, which supports offline sales with online data synchronization.",
    images: POINTOFSALES,
    uploadStatus: 'regular',
  },
  {
    id: 31,
    tag: 'Digital Branding',
    title: 'Promotion Video Teknik Manufaktur Universitas Surabaya',
    desc: 'The promotional video showcases the Manufacturing Engineering program at the University of Surabaya, highlighting its unique features and offerings.',
    images: MANUFACTURINGUBAYA,
    uploadStatus: 'regular',
  },
  {
    id: 32,
    tag: 'Digital Branding',
    title: 'Rebranding Digital Marketing Queen Aisya Salon & SPA',
    desc: 'Revitalizing the digital marketing strategy for Queen Aisya Salon & SPA through a comprehensive rebranding initiative.',
    images: SPA,
    uploadStatus: 'regular',
  },
  {
    id: 33,
    tag: 'Digital Branding',
    title: 'Aerial Video Company Profile Video PT JMF',
    desc: 'Crafting an engaging aerial company profile video for PT JMF, showcasing their expertise and services from a captivating perspective.',
    images: 'https://jmfertilizo.id/wp-content/uploads/2023/06/produksi-756x1024.png',
    uploadStatus: 'regular',
  },
  {
    id: 34,
    tag: 'Startup Incubator',
    title: 'Sistem Informasi Manajemen Rumah Sakit - MEDIFY',
    desc: "Digital startup incubation aimed at guiding and training small startups so they can develop with a focus on hospital management systems in terms of investment, commercial & managed services.",
    images: 'https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExNmI0bXFwaG5pemE4dzMzdDdpb3JlMGNpejFxdXlvZm1pbnVsMGNkcyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/s3hbXDQTg8IAjhfDX3/giphy.webp',
    uploadStatus: 'regular',
  },
  {
    id: 35,
    tag: 'Startup Incubator',
    title: 'Sistem Ruang Komunitas - RUANGTEMU',
    desc: "Digital-based startup incubation which aims to provide news about community events and accommodate event management that focuses on Tech Investment & Tech Consultants.",
    images: 'https://media.giphy.com/media/iDPVSQUPiaFgu6VAAD/giphy.gif',
    uploadStatus: 'regular',
  },
  {
    id: 36,
    tag: 'Startup Incubator',
    title: 'Sistem Aplikasi Kontraktor (Manajemen dan Monitoring Proyek Konstruksi)',
    desc: "Digital-based startup incubation which aims to provide a project monitoring application system for construction contractors, in terms of Tech Consultant, Commercial & Managed Services.",
    images: KONTRAKTOR,
    uploadStatus: 'regular',
  },
  {
    id: 37,
    tag: 'Startup Incubator',
    title: 'Sistem Aplikasi Manufaktur Industri',
    desc: "Digital-based startup incubation which aims to provide manufacturing application systems for industry, both in terms of Tech Consultant & Managed Services.",
    images: MANUFAKTURINDUSTRI,
    uploadStatus: 'regular',
  },
  {
    id: 38,
    tag: 'Startup Incubator',
    title: 'Sistem Koperasi CALCIUS',
    desc: "Digital-based startup incubation which aims to provide cooperative organization information systems, both in terms of Tech Investment & Business Consultant.",
    images: CALCIUSINKUBATOR,
    uploadStatus: 'regular',
  },
  {
    id: 39,
    tag: 'IT Consultant',
    title: 'Perencanaan Sistem Pemetaan Bangunan',
    desc: "Technology implementation planning for building mapping system using digital imagery, which aims to document buildings accurately, facilitating renovation or maintenance planning for various purposes, in collaboration with Telkom Indonesia for the Lamongan district government.",
    images: 'https://media.giphy.com/media/VNZdS11NFqGsGEy0lZ/giphy.gif',
    uploadStatus: 'regular',
  },
  {
    id: 40,
    tag: 'IT Consultant',
    title: 'Perencanaan Sistem Monitoring Ternak',
    desc: "Sistem Monitoring Ternak is a technology used to monitor the condition and behavior of livestock in real-time. The aim is to improve livestock welfare, livestock efficiency, and identify health or behavioral problems early, in collaboration with Telkom Indonesia for the Lamongan district government.",
    images: 'https://media.giphy.com/media/zEjxFNVyKDeypdCQcg/giphy.gif',
    uploadStatus: 'regular',
  },
  {
    id: 41,
    tag: 'IT Consultant',
    title: 'Perencanaan Sistem Sekolah',
    desc: "School system planning is an IT consultant activity that includes requirement analysis and system design for developing application systems in schools to improve operational efficiency and support better learning, in collaboration with Telkom Indonesia for the Lamongan district government.",
    images: 'https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExbnR5Zm95NTA1ZGFlM29idGQ0YmF2MGd1ZTZreDdhOXVzbmsxZ2U2dSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/aUYjHboFIVZV5rjrax/giphy.gif',
    uploadStatus: 'regular',
  },
  {
    id: 42,
    tag: 'IT Consultant',
    title: 'Analisa Pengembangan dan Kebutuhan KOPERASI KANA',
    desc: "The analysis and development of application systems involving planning, design, and development to meet the user needs of KOPERASI KANA. This project collaborates with a team from various disciplines such as system analysts, UI/UX designers, as well as security and database experts.",
    images: KANA,
    uploadStatus: 'regular',
  },
  {
    id: 43,
    tag: 'IT Consultant',
    title: 'Perencanaan Pengembangan Sistem PPDB (Penerimaan Peserta Didik Baru)',
    desc: "The PPDB Application System is an online platform for managing the admission of new students to schools, including registration, data verification, selection, announcement of selection results efficiently and transparently. This project collaborates with the Sepuluh Nopember Technological Institute.",
    images: PPDBITS,
    uploadStatus: 'regular',
  },
  {
    id: 44,
    tag: 'IT Consultant',
    title: 'Analisa Pengembangan dan Kebutuhan Koperasi BMT MUDA',
    desc: "The analysis and development of application systems involving planning and design to meet the user needs of KOPERASI BMT MUDA. This project collaborates with a team from various disciplines such as system analysts, UI/UX designers, as well as security and database experts.",
    images: ANALISABMTMUDA,
    uploadStatus: 'regular',
  },
  {
    id: 45,
    tag: 'IT Consultant',
    title: 'POC dan Perencanaan Pengembangan Sistem Job Center Berau Coal',
    desc: "Development of a prototype application in the form of a POC project to prove that the design concept for the job center application can be carried out and implemented.",
    images: POCJOBCENTER,
    uploadStatus: 'regular',
  },
  {
    id: 46,
    tag: 'IT Consultant',
    title: 'POC dan Perencanaan Pengembangan Sistem Kepatuhan Peraturan Berau Coal',
    desc: "Development of a prototype application in the form of a POC project to replicate and prove the design concept for regulatory compliance system application can be carried out and implemented.",
    images: POCRCS,
    uploadStatus: 'regular',
  },
  // {
  //   id: 47,
  //   tag: 'IT Consultant',
  //   title: 'Analisa Sistem Tambang Untuk Kebutuhan Pengembangan Project Tambang',
  //   desc: "The fertilizer factory application analysis process involves processing data on phosphate mines as an indicator of the amount of phosphate content in each mine. The results of the analysis are used to prove that the development of mining applications can be implemented.",
  //   images: POCOPTA,
  //   uploadStatus: 'regular',
  // },
];
