import React, { lazy, Suspense } from 'react';

// import component
import LandingPagesSectionOne from './LandingPagesSectionOne';
import LandingPagesSectionTwo from './LandingPagesSectionTwo';
import LandingPagesSectionFour from './LandingPagesSectionFour';
import LandingPagesSectionFive from './LandingPagesSectionFive';
import LandingPagesSectionSix from './LandingPagesSectionSix';
import HappyClientComponent from '../../components/HappyClientComponent';
import CTASectionComponent from '../../components/CTASectionComponent';
// import LandingPagesSectionEight from "./LandingPagesSectionEight";
import ScrollTop from '../../components/ScrollTop';

const LandingPagesSectionThree = lazy(() =>
  import('./LandingPagesSectionThree')
);

const LandingPagesSectionSeven = lazy(() =>
  import('./LandingPagesSectionSeven')
);

const LandingPagesIndex = () => {
  return (
    <div>
      <ScrollTop />
      <LandingPagesSectionOne />
      <div className='container'>
        <LandingPagesSectionTwo />
      </div>
      <Suspense fallback={<LoadingIndicator />}>
        <LandingPagesSectionThree />
      </Suspense>
      <div className='container'>
        <LandingPagesSectionFour />
      </div>
      <LandingPagesSectionFive />
      <LandingPagesSectionSix />
      <HappyClientComponent />
      <Suspense fallback={<LoadingIndicator />}>
        <LandingPagesSectionSeven />
      </Suspense>
      {/* TODO This feature is disabled in deploy V1 */}
      {/* <LandingPagesSectionEight /> */}
      <CTASectionComponent
        title='Ready to work with us?'
        desc='Effective communication is the cornerstone of success'
      />
    </div>
  );
};

const LoadingIndicator = () => {
  return (
    <div className='loading'>
      <p>Loading...</p>
      {/* You can customize the loading indicator as needed */}
    </div>
  );
};

export default LandingPagesIndex;
