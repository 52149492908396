import { FirstAid, Money, Student } from "@phosphor-icons/react";

export const dataProduct = [
  {
    id: 1,
    name: "Healthcare Management System",
    description:
      "Manage digital medical records, services, support, back office, and other hospital managerial support features",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <FirstAid size={32} color='#3182FF' weight='fill' />,
    isFeatured: true,
    show: true,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  {
    id: 2,
    name: "Accounting and Cooperative System",
    description:
      "Providing financial service systems, account management and cooperative managerial systems",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Money size={32} color='#3182ff' weight='fill' />,
    isFeatured: true,
    show: true,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  {
    id: 3,
    name: "Project Monitoring System",
    description:
      "Providing system services for planning, implementation and evaluation on projects.",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Student size={32} color='#3182ff' weight='fill' />,
    isFeatured: true,
    show: true,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  // The data from entry 4 until the last entry is dummy data used for the /product section, which is not yet live. Therefore, the /product section will be hidden from the public.
  {
    id: 4,
    name: "Lorem Ipsum 1",
    description:
      "Providing system services for planning, implementation and evaluation on projects.",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Student size={32} color='#3182ff' weight='fill' />,
    isFeatured: false,
    show: false,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  {
    id: 5,
    name: "Lorem Ipsum 2",
    description:
      "Providing system services for planning, implementation and evaluation on projects.",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Student size={32} color='#3182ff' weight='fill' />,
    isFeatured: false,
    show: false,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  {
    id: 6,
    name: "Lorem Ipsum 3",
    description:
      "Providing system services for planning, implementation and evaluation on projects.",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Student size={32} color='#3182ff' weight='fill' />,
    isFeatured: false,
    show: false,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  {
    id: 7,
    name: "Lorem Ipsum 4",
    description:
      "Providing system services for planning, implementation and evaluation on projects.",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Student size={32} color='#3182ff' weight='fill' />,
    isFeatured: false,
    show: false,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
  {
    id: 8,
    name: "Lorem Ipsum 5",
    description:
      "Providing system services for planning, implementation and evaluation on projects.",
    modules: [
      {
        id: 1,
        title: "Modul 1 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 2,
        title: "Modul 2 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
      {
        id: 3,
        title: "Modul 3 - [Nama Modul]",
        desc: "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
      },
    ],
    longDescription:
      "Lorem ipsum del culametan metmet Lorem ipsum del culametan metmetLorem ipsum del culametan metmetLorem ipsum del culametan metmet.",
    icon: <Student size={32} color='#3182ff' weight='fill' />,
    isFeatured: false,
    show: false,
    image:
      "https://www.leadsquared.com/wp-content/uploads/2023/03/hospital-management-system.jpg",
  },
];
