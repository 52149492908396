import React from 'react';
import { styled } from 'styled-components';

const WorkDetailTestimoniImage = () => {
  return (
    <WorkDetailTestimoniImageStyled>
      <div className='container'>
        <div>
          <img
            src='https://www.meetingsnet.com/sites/meetingsnet.com/files/styles/article_featured_retina/public/ContractNeg1020.jpg?itok=urIpNmlO'
            alt='meeting'
          />
        </div>
        <div className='testimonial'>
          <div className='title heading-large'>What the client said?</div>
          <div className='information'>
            <div className='open-qoute'>
              <svg
                width='36'
                height='36'
                viewBox='0 0 36 36'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M20.8125 25.875V15.75H30.375C30.6734 15.75 30.9595 15.8685 31.1705 16.0795C31.3815 16.2905 31.5 16.5766 31.5 16.875V25.875C31.5 26.1734 31.3815 26.4595 31.1705 26.6705C30.9595 26.8815 30.6734 27 30.375 27H21.9375C21.6391 27 21.353 26.8815 21.142 26.6705C20.931 26.4595 20.8125 26.1734 20.8125 25.875ZM5.625 27H14.0625C14.3609 27 14.647 26.8815 14.858 26.6705C15.069 26.4595 15.1875 26.1734 15.1875 25.875V16.875C15.1875 16.5766 15.069 16.2905 14.858 16.0795C14.647 15.8685 14.3609 15.75 14.0625 15.75H4.5V25.875C4.5 26.1734 4.61853 26.4595 4.8295 26.6705C5.04048 26.8815 5.32663 27 5.625 27Z'
                  fill='#616161'
                />
                <path
                  d='M21.9375 28.125H30.375C30.9717 28.125 31.544 27.8879 31.966 27.466C32.3879 27.044 32.625 26.4717 32.625 25.875V16.875C32.625 16.2783 32.3879 15.706 31.966 15.284C31.544 14.8621 30.9717 14.625 30.375 14.625H21.9375V13.5C21.9375 12.3065 22.4116 11.1619 23.2555 10.318C24.0994 9.47411 25.244 9 26.4375 9C26.7359 9 27.022 8.88147 27.233 8.6705C27.444 8.45952 27.5625 8.17337 27.5625 7.875C27.5625 7.57663 27.444 7.29048 27.233 7.0795C27.022 6.86853 26.7359 6.75 26.4375 6.75C24.6479 6.75186 22.9321 7.46362 21.6666 8.72908C20.4011 9.99455 19.6894 11.7104 19.6875 13.5V25.875C19.6875 26.4717 19.9246 27.044 20.3465 27.466C20.7685 27.8879 21.3408 28.125 21.9375 28.125ZM21.9375 16.875H30.375V25.875H21.9375V16.875ZM5.625 28.125H14.0625C14.6592 28.125 15.2315 27.8879 15.6535 27.466C16.0754 27.044 16.3125 26.4717 16.3125 25.875V16.875C16.3125 16.2783 16.0754 15.706 15.6535 15.284C15.2315 14.8621 14.6592 14.625 14.0625 14.625H5.625V13.5C5.625 12.3065 6.09911 11.1619 6.94302 10.318C7.78693 9.47411 8.93153 9 10.125 9C10.4234 9 10.7095 8.88147 10.9205 8.6705C11.1315 8.45952 11.25 8.17337 11.25 7.875C11.25 7.57663 11.1315 7.29048 10.9205 7.0795C10.7095 6.86853 10.4234 6.75 10.125 6.75C8.33536 6.75186 6.61955 7.46362 5.35408 8.72908C4.08862 9.99455 3.37686 11.7104 3.375 13.5V25.875C3.375 26.4717 3.61205 27.044 4.03401 27.466C4.45597 27.8879 5.02826 28.125 5.625 28.125ZM5.625 16.875H14.0625V25.875H5.625V16.875Z'
                  fill='#616161'
                />
              </svg>
            </div>
            <div className='desc paragraph-medium regular'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
              massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus.
            </div>
            <div className='close-qoute'>
              <svg
                width='36'
                height='36'
                viewBox='0 0 36 36'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M15.1875 25.875V15.75H5.625C5.32663 15.75 5.04048 15.8685 4.82951 16.0795C4.61853 16.2905 4.5 16.5766 4.5 16.875V25.875C4.5 26.1734 4.61853 26.4595 4.82951 26.6705C5.04048 26.8815 5.32663 27 5.625 27H14.0625C14.3609 27 14.647 26.8815 14.858 26.6705C15.069 26.4595 15.1875 26.1734 15.1875 25.875ZM30.375 27H21.9375C21.6391 27 21.353 26.8815 21.142 26.6705C20.931 26.4595 20.8125 26.1734 20.8125 25.875V16.875C20.8125 16.5766 20.931 16.2905 21.142 16.0795C21.353 15.8685 21.6391 15.75 21.9375 15.75H31.5V25.875C31.5 26.1734 31.3815 26.4595 31.1705 26.6705C30.9595 26.8815 30.6734 27 30.375 27Z'
                  fill='#616161'
                />
                <path
                  d='M14.0625 28.125H5.625C5.02826 28.125 4.45597 27.8879 4.03401 27.466C3.61205 27.044 3.375 26.4717 3.375 25.875V16.875C3.375 16.2783 3.61205 15.706 4.03401 15.284C4.45597 14.8621 5.02826 14.625 5.625 14.625H14.0625V13.5C14.0625 12.3065 13.5884 11.1619 12.7445 10.318C11.9006 9.47411 10.756 9 9.5625 9C9.26413 9 8.97798 8.88147 8.767 8.6705C8.55603 8.45952 8.4375 8.17337 8.4375 7.875C8.4375 7.57663 8.55603 7.29048 8.767 7.0795C8.97798 6.86853 9.26413 6.75 9.5625 6.75C11.3521 6.75186 13.0679 7.46362 14.3334 8.72908C15.5989 9.99455 16.3106 11.7104 16.3125 13.5V25.875C16.3125 26.4717 16.0754 27.044 15.6535 27.466C15.2315 27.8879 14.6592 28.125 14.0625 28.125ZM14.0625 16.875H5.625V25.875H14.0625V16.875ZM30.375 28.125H21.9375C21.3408 28.125 20.7685 27.8879 20.3465 27.466C19.9246 27.044 19.6875 26.4717 19.6875 25.875V16.875C19.6875 16.2783 19.9246 15.706 20.3465 15.284C20.7685 14.8621 21.3408 14.625 21.9375 14.625H30.375V13.5C30.375 12.3065 29.9009 11.1619 29.057 10.318C28.2131 9.47411 27.0685 9 25.875 9C25.5766 9 25.2905 8.88147 25.0795 8.6705C24.8685 8.45952 24.75 8.17337 24.75 7.875C24.75 7.57663 24.8685 7.29048 25.0795 7.0795C25.2905 6.86853 25.5766 6.75 25.875 6.75C27.6646 6.75186 29.3804 7.46362 30.6459 8.72908C31.9114 9.99455 32.6231 11.7104 32.625 13.5V25.875C32.625 26.4717 32.3879 27.044 31.966 27.466C31.544 27.8879 30.9717 28.125 30.375 28.125ZM30.375 16.875H21.9375V25.875H30.375V16.875Z'
                  fill='#616161'
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </WorkDetailTestimoniImageStyled>
  );
};

const WorkDetailTestimoniImageStyled = styled.div`
  padding: 60px 0px;
  background: var(--hxvr-neutral-40);
  .container {
    display: flex;
    align-items: center;
    gap: 32px;
    img {
      width: 100%;
    }
    .testimonial {
      padding: 20px 20px;
      border-radius: 12px;
      background: #fff;
      box-shadow: 0px 12px 24px -4px rgba(59, 65, 69, 0.08),
        0px 0px 12px -4px rgba(59, 65, 69, 0.08);
      display: flex;
      flex-direction: column;
      gap: 24px;

      .information {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .close-qoute {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
  }
`;

export default WorkDetailTestimoniImage;
